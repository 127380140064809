<template>
    <div class="referrer">
        <!--导航-->
        <rxNavBar title="选择新租客"></rxNavBar>
        <div class="communityDiv">
            <van-search  placeholder="请输入搜索关键词" v-model="phoneSearch" size="large" @input="quaryPhoneList()"/>
        </div>
        <div class="mobileList" v-for="(item,index) in mobileList" :key="index">
            <div class="mobile" @click="chooseMobile(index)">{{item.userName}}-{{item.mobileStr}}</div>
        </div>
    </div>
</template>

<script>
    import { NavBar,search} from 'vant';
    import {remoteMobileSearch} from "../../../getData/getData";
    import {responseUtil} from "../../../libs/rongxunUtil";
    import eventBus from "../../../components/rongxun/rx-navBar/envbus.js"
    import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
    export default {
        components: {
            [NavBar.name]:NavBar,
            [search.name]:search,
            rxNavBar
        },
        data() {
            return {
                phoneSearch:'',//搜索关键字
                mobileList:[],
                mobile:''
            }
        },
        mounted() {
            // this.quaryPhoneList()
        },
        methods: {
            //根据输入的号码模糊查询手机号列表
            quaryPhoneList(){
                let that = this
                if(that.phoneSearch.length < 8){
                  return
                }
                let queryData = {}
                queryData.searchInfo = that.phoneSearch
                remoteMobileSearch(queryData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.mobileList = response.data.data.data
                        for (var index in that.mobileList) {
                            that.$set(that.mobileList[index],'mobileStr',that.geTel(that.mobileList[index].mobile) )
                        }
                    })
                })
            },
            // 手机号隐藏四位
            geTel(tel) {
                var reg = /^(\d{3})\d{4}(\d{4})$/
                return tel.replace(reg, '$1****$2')
            },

            chooseMobile(index){
                this.mobile = this.mobileList[index].mobile
                let data = {
                    mobile:this.mobile
                }
                eventBus.$emit('selectRenterMobileList',data);
                //调用router回退页面
                this.$router.go(-1);
            }


        },

        //路由跳转时销毁全局bus里的监听事件
        beforeDestroy() {
            eventBus.$off('selectRenterMobileList')
        }
    }
</script>

<style lang="less" scoped >
    .communityDiv{
        height: 60px;
    }
    .mobile{
        height: 35px;
        line-height: 35px;
        width: 345px;
        margin-left: 15px;
        margin-bottom: 15px;
        background-color: white;
        border-radius: 8px;
        font-size: 15px;
        text-align: center;
    }
</style>
